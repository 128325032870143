/* You can add global styles to this file, and also import other style files */

@import "theme/index.scss";

html,
body {
  height: 100%;
}
html {
  overscroll-behavior: none;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: $gray-font;
  touch-action: none;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.mat-form-field {
  width: 100%;
}

.mat-raised-button,
.mat-flat-button,
.mat-stroked-button,
.mat-basic  {
  font-weight: 600 !important;
}
